import React from "react";
import { apiImagePath } from "../../utils/functions/index";
import Section from "../ui/section/Section";
import Article from "../ui/articles/Article";
import BannerParalax from "../ui/banners/BannerParalax";
import Button from "../ui/buttons/Button";
import Heading from "../ui/text/Heading";
import Container from "../ui/containers/Container";
import parser from "react-html-parser";

export default function Paralax({ data }) {
  const sectionName = data.title
    ? data.title.toLowerCase().replace(" ", "-")
    : "paralax";

  return (
    <BannerParalax image={apiImagePath(data.images)}>
      <Container>
        <Section name={sectionName}>
          {data.title && (
            <Heading as="h2" shadow centered>
              {data.title}
            </Heading>
          )}

          <Article centered>
            {data.content && parser(data.content)}
            {data.slug && (
              <Button type="redirect" to={`/${data.slug}`} theme="secondary">
                READ MORE
              </Button>
            )}
          </Article>
        </Section>
      </Container>
    </BannerParalax>
  );
}
