import _ from "lodash";
import React, { Fragment, useEffect } from "react";
import history from "../../utils/history/history";
import useResources from "../resources/useResources";
import { Slider } from "../ui/sliders/Slider";
import GettingStarted from "../modules/GettingStarted";
import PageModules from "../modules/PageModules";
import smoothscroll from 'smoothscroll-polyfill';

function Home({ resource }) {
  const page = useResources(resource);

  // kick off the polyfill!
  smoothscroll.polyfill();

  useEffect(() => {
    const element = document.getElementById(
      history.location.hash.replace("#", "")
    );

    setTimeout(() => {
      window.scrollTo({
        behavior: element ? "smooth" : "auto",
        top: element ? element.offsetTop : 0
      });
    }, 100);
  });

  const gallery = [
    {
      image: "/images/slider/hero-slider-01.jpg",
      imageMobie: "/images/slider_mobi/hero-slider-01@2x.jpg",
      title: "CAREER COUNSELLING",
      subtitle: "For High-School Learners and Young Adults",
      button: 'Find out More',
      link: '/pdf/Brochure-My2Morrow-Career-Counselling-for-Young-Adults.pdf'
    },
    {
      image: "/images/slider/hero-slider-02.jpg",
      imageMobie: "/images/slider_mobi/hero-slider-02@2x.jpg",
      title: "Subject Choice",
      subtitle: "Assessment for Teens",
      button: 'Find out More',
      link: '/pdf/Brochure-My2Morrow-Subject-Choice-Assessment.pdf'
    },
    {
      image: "/images/slider/hero-slider-03-2020.jpg",
      imageMobie: "/images/slider_mobi/hero-slider-03@2x.jpg",
      title: "Career Change",
      subtitle: "Counselling for Adults",
      button: 'Find out More',
      link: '/pdf/Brochure-My2Morrow-Career-Transition-Counselling.pdf'
    }
  ];

  return (
    <Fragment>
      <Slider items={gallery} />
      <PageModules page={page} />
      <GettingStarted />
    </Fragment>
  );
}

export default Home;
