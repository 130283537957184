import React from "react";
import styled from "styled-components";
import { apiImagePath } from "../../../utils/functions/index";
import Grid from "../grid/Grid";
import GridItem from "../grid/GridItem";
import Heading from "../text/Heading";
import Paragraph from "../text/Paragraph";

const ContentParagraph = styled(Paragraph)`
  min-height: 140px;
  @media (max-width: 762px) {
    min-height: auto;
    text-align: center;
  }
`;

export default function ContentBlock({ data }) {
  const sizeMap = {
    "3": ["col-sm-6", "col-md-4", "col-lg-4"],
    "4": ["col-sm-6", "col-md-3", "col-lg-3"]
  };

  const sizes = [sizeMap[data.size].join(" ")];

  return (
    <GridItem sizes={sizes}>
      {data.image && <img src={apiImagePath(data.image)} alt="" />}
      <Heading as="h2" centermobile primary>
        {data.title}
      </Heading>

      {data.content && <ContentParagraph>{data.content}</ContentParagraph>}
    </GridItem>
  );
}
