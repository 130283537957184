import React, { Fragment } from "react";

import Paralax from "./Paralax";
import Standard from "./Standard";
import Vertical from "./Vertical";

const Module = ({ type, data }) => {
  const renderModule = () => {
    switch (type) {
      case "standard":
        return <Standard data={data} />;

      case "vertical":
        return <Vertical data={data} />;

      case "paralax":
        return <Paralax data={data} />;

      default:
        break;
    }
  };

  return <Fragment>{renderModule(data)}</Fragment>;
};

export default Module;
