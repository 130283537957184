import styled from "styled-components";

const NavSpacer = styled.div`
  height: 141px;

  @media (max-width: 762px) {
    height: 107px;
  }
`;

export default NavSpacer;
