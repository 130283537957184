import styled from "styled-components";
import { Link } from "react-router-dom";

const NavLink = styled(Link)`
  color: ${props => (props.theme === "dark" ? "#35375B" : "#fff")};
  text-decoration: none;
  font-size: 20px;

  @media (max-width: 1023px) {
    padding: 10px 0;
    color: #35375b;
    font-size: 16px;
  }
`;

export default NavLink;
