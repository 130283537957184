import React, { useState, useEffect } from "react";
import Logo from "../logo";
import Nav from "./Nav";
import NavHeader from "./NavHeader";
import NavHeaderItem from "./NavHeaderItem";
import NavList from "./NavList";
import NavItem from "./NavItem";
import NavLink from "./NavLink";
import Container from "../containers/Container";
import Button from "../buttons/Button";
import { IconList, Icon } from "../icons";
import history from "../../../utils/history/history";
import Hamburger from "./Hamburger";

const routePath = history.location.pathname;
const navItems = [
  {
    name: "HOME",
    url: "/",
    theme: "light"
  },
  {
    name: "ABOUT US",
    url: "/about-us",
    theme: "dark"
  },
  {
    name: "CONTEXT",
    url: "/context",
    theme: "dark"
  },
  {
    name: "PROCESS",
    url: "/#the-process",
    theme: "dark"
  },
  {
    name: "DELIVERABLES",
    url: "/#deliverables",
    theme: "dark"
  },
  {
    name: "FAQ's",
    url: "/faqs",
    theme: "dark"
  },
  {
    name: "FEES",
    url: "/#fees",
    theme: "dark"
  },
  {
    void: true,
    name: "Form Thank you page",
    url: "/thank-you",
    theme: "dark"
  },
  {
    void: true,
    name: "START CONVERSATION",
    url: "/start-a-conversation",
    theme: "dark"
  }
];

const Navbar = props => {
  const [currentRoute, setCurrentRoute] = useState(routePath);
  useEffect(() => {
    history.listen(location => {
      setCurrentRoute(location.pathname);
    });
  });

  const [navTheme, setNavTheme] = useState("light");
  useEffect(() => {
    for (let item of navItems) {
      if (item.url === currentRoute) {
        setNavTheme(item.theme);
      }
    }
  });

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return function cleanup() {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const [visible, setVisible] = useState(true);
  const handleScroll = () => {
    setVisible(window.pageYOffset < 100);
    console.log(visible);
    setActive(false);
  };

  const [isActive, setActive] = useState(false);
  const handleMenuToggle = () => {
    setActive(!isActive);
  };

  const renderNavItems = () => {
    let items = navItems.map((item, i) => {
      if (!item.void) {
        return (
          <NavItem key={i} onClick={handleMenuToggle}>
            <NavLink theme={navTheme} to={item.url}>
              {item.name}
            </NavLink>
          </NavItem>
        );
      }
    });

    if (window.innerWidth < 1024) {
      items.push(
        <NavItem>
          <Button centered type="redirect" to="/start-a-conversation">
            START CONVERSATION
          </Button>
        </NavItem>,

        <NavItem>
          <IconList theme={navTheme}>
            <a to="https://www.facebook.com/my2morrow/">
              <Icon theme="dark" name="facebook" />
            </a>

            <a to="https://www.instagram.com/my2morrowstarts2day/">
              <Icon theme="dark" name="instagram" />
            </a>
          </IconList>
        </NavItem>
      );
    }

    return items;
  };

  return (
    <Nav visible={visible} theme={navTheme}>
      <Container nopaddingmobile>
        <NavHeader visible={visible}>
          <NavHeaderItem hideonmobile>
            <IconList theme={navTheme}>
              <a href="https://www.facebook.com/my2morrow/" target="_blank">
                <Icon theme={navTheme} name="facebook" />
              </a>
              <a
                href="https://www.instagram.com/my2morrowstarts2day/"
                target="_blank"
              >
                <Icon theme={navTheme} name="instagram" />
              </a>
            </IconList>
          </NavHeaderItem>

          <NavHeaderItem>
            <Logo theme={navTheme} visible={visible} />
          </NavHeaderItem>

          <NavHeaderItem hideonmobile>
            <Button type="redirect" to="/start-a-conversation">
              START CONVERSATION
            </Button>
          </NavHeaderItem>
        </NavHeader>

        <NavList visible={visible} isActive={isActive}>
          {renderNavItems()}
        </NavList>
      </Container>

      <Hamburger
        onClick={handleMenuToggle}
        isActive={isActive}
        visible={visible}
      />
    </Nav>
  );
};

export default Navbar;
