import React from "react";
import styled from "styled-components";
import Container from "../containers/Container";
import Heading from "../text/Heading";
import Paragraph from "../text/Paragraph";
import { IconList, Icon } from "../icons";
import Button from "../buttons/Button";
import Grid from "../grid/Grid";
import GridItem from "../grid/GridItem";
import TeamBlock from "./TeamBlock";
import Logo from "../logo";
import NavList from "../navbar/NavList";
import NavItem from "../navbar/NavItem";
import NavLink from "../navbar/NavLink";
import FooterBottom from "./FooterBottom";
import Section from "../section/Section";

const FooterWrapper = styled.footer`
  background: #35375b;
  text-align: center;
`;

const navItems = [
  {
    name: "HOME",
    url: "/",
    theme: "light"
  },
  {
    name: "ABOUT US",
    url: "/about-us",
    theme: "dark"
  },
  {
    name: "CONTEXT",
    url: "/context",
    theme: "dark"
  },
  {
    name: "PROCESS",
    url: "/#the-process",
    theme: "dark"
  },
  {
    name: "DELIVERABLES",
    url: "/#deliverables",
    theme: "dark"
  },
  {
    name: "FAQ's",
    url: "/faqs",
    theme: "dark"
  },
  {
    name: "FEES",
    url: "/#fees",
    theme: "dark"
  }
];

const Footer = () => {
  const renderNavItems = () => {
    return navItems.map((item, i) => {
      return (
        <NavItem key={i}>
          <NavLink to={item.url}>{item.name}</NavLink>
        </NavItem>
      );
    });
  };

  return (
    <FooterWrapper>
      <Container>
        <Section>
          <Grid>
            <GridItem sizes={["col-md-12"]}>
              <Heading shadow primary as="h2" centered>
                CONTACT US
              </Heading>

              <Paragraph secondary centered>
                Should you wish to learn more about the offering, please
                contact:
              </Paragraph>
            </GridItem>
          </Grid>

          <Grid>
            <GridItem sizes={["col-md-12"]}>
              <img
                src="/images/conversation@2x.png"
                alt=""
                style={{ width: "100px" }}
              />

              <br />

              <Button
                theme="secondary"
                margintop="true"
                marginbottom="true"
                type="redirect"
                to="/start-a-conversation"
              >
                START CONVERSATION
              </Button>

              <Heading as="h4">VISIT OUR SOCIAL PAGES</Heading>

              <IconList centered margintop="true" marginbottom="true">
                <a href="https://www.facebook.com/my2morrow/" target="_blank">
                  <Icon theme="light" name="facebook" />
                </a>

                <a
                  href="https://www.instagram.com/my2morrowstarts2day/"
                  target="_blank"
                >
                  <Icon theme="light" name="instagram" />
                </a>
              </IconList>
            </GridItem>
          </Grid>

          <Grid>
            <GridItem sizes={["col-md-6"]}>
              <TeamBlock hasBorderRight>
                <Heading shadow primary as="h4">
                  Dr Brenda Lombard
                </Heading>
                <Paragraph secondary>Clinical Psychologist</Paragraph>
                <Paragraph secondary>E-mail: support@my2morrow.com</Paragraph>
              </TeamBlock>
            </GridItem>

            <GridItem sizes={["col-md-6"]}>
              <TeamBlock>
                <Heading shadow primary as="h4">
                  Corli Brits
                </Heading>
                <Paragraph secondary> Psychometrist</Paragraph>
                <Paragraph secondary> E-mail: support@my2morrow.com</Paragraph>
              </TeamBlock>
            </GridItem>
          </Grid>
          <br />
          <Grid>
            <GridItem>
              <Logo />
            </GridItem>
          </Grid>
          <br />
          <NavList>{renderNavItems()}</NavList>
        </Section>
      </Container>
      <FooterBottom />
    </FooterWrapper>
  );
};

export default Footer;
