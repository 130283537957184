import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "../components/pages/Home";
import AboutUs from "../components/pages/AboutUs";
import Context from "../components/pages/Context";
import Faqs from "../components/pages/Faqs";
import StarAConversation from "../components/pages/StarAConversation";
import ThankYou from "../components/pages/ThankYou";

function Routes() {
  return (
    <Switch>
      {/* HOME */}
      <Route
        path="/"
        render={props => <Home {...props} resource="pages/home" />}
        exact
      />

      {/* ABOUT US */}
      <Route
        path="/about-us"
        render={props => <AboutUs {...props} resource="pages/about-us" />}
        exact
      />

      {/* CONTEXT */}
      <Route
        path="/context"
        render={props => <Context {...props} resource="pages/context" />}
        exact
      />

      {/* FAQS */}
      <Route
        path="/faqs"
        render={props => <Faqs {...props} resource="pages/faqs" />}
        exact
      />

      {/* CONTACT */}
      <Route
        path="/start-a-conversation"
        render={props => (
          <StarAConversation {...props} resource="start-a-conversation" />
        )}
        exact
      />

      {/* FORM SUCCESS */}
      <Route
        path="/thank-you"
        render={props => <ThankYou {...props} resource="thank-you" />}
        exact
      />
    </Switch>
  );
}

export default Routes;
