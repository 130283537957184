import React from "react";
import styled from "styled-components";
import Container from "../containers/Container";

const date = new Date();

const Wrapper = styled.div`
  background: #272945;
  text-align: center;
  padding: 5px 0;
`;

const FooterLinks = styled.ul`
  display: flex;
  padding: 0;
  justify-content: center;

  @media (max-width: 762px) {
    display: block;
    align-items: center;
    text-align: center;
  }
`;

const FooterLink = styled.li`
  list-style: none;
  border-right: 1px solid #fff;
  font-size: 14px;

  &:last-child {
    border: none;
    padding-right: 0;
    padding-left: 10px;
  }
  color: #fff;

  @media (max-width: 762px) {
    border: none;
    padding: 0;
    margin-bottom: 5px;
  }

  &:last-child {
    border: none;
    padding-right: 0;
    padding-left: 0;
  }
`;

const FooterBottom = () => {
  return (
    <Wrapper>
      <Container>
        <FooterLinks>
          <FooterLink>©{date.getFullYear()} All Rights Reserved.</FooterLink>
          <FooterLink>
            Powered by{" "}
            <a
              style={{ color: "#fff", textDecoration: "none" }}
              href="https://optimalonline.co.za"
              target="_blank"
              rel="noopener noreferrer"
            >
              Optimal Online
            </a>
          </FooterLink>
        </FooterLinks>
      </Container>
    </Wrapper>
  );
};

export default FooterBottom;
